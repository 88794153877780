// COLORS

$green: #56DA6B;
$yellow: #FFB23E;
$blue: #3971FF;
$purple: #FF9696;

$backgroundColor: #F6F6FA;
$borderColor: #EAEEF1;

$textMain: #232323;
$textSecond: #A5A5A5;
$textThird: #686875;
$textThirdDarken: darken($textThird, 10%);

$themeMain: #EB353D;
$themeMainLighten: #FFDEDE;
$themeMainDarken: darken($themeMain, 10%);

// DARK THEME

$darkThemeMain: #e65100;
$darkThemeText: #ffffff;
$darkThemeBackground: #1E1E1E;
$darkThemeBorder: #EBEBEB;

// COMPONENTS

$settingsBackButtonHeight: 44px;
