.Dialog {
  &-container {
    height: 100%;
  }

  &-scrollPaper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-scrollBody {
    text-align: center;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;

    &:after {
      content: '';
      width: 0;
      height: 100%;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &-paper {
    margin: 32px;
    position: relative;
    overflow-y: auto;
    display: inline-block;
    vertical-align: middle;
    text-align: left;

    &-fullWidth {
      width: calc(100% - 64px);
    }

    &-maxWidth {
      &-small {
        max-width: 444px;
      }

      &-medium {
        max-width: 960px;
      }

      &-large {
        max-width: 1920px;
      }
    }
  }
}

.DialogTitle {
  flex: 0 0 auto;
  margin: 0;
  padding: 32px 32px 0;
  color: var(--text-main);
}

.DialogContent {
  flex: 1 1 auto;
  padding: 24px 32px;
}

.DialogActions {
  flex: 0 0 auto;
  display: flex;
  padding: 0 32px 32px;
  align-items: center;
  justify-content: flex-end;
}
