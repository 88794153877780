@import '../../../../scss/variables';

.CarInfo {
  position: relative;
  margin-top: 32px;

  &-online {
    position: absolute;
    left: 45px;
    top: -14px;
    width: 28px;
    height: 28px;

    path {
      stroke: var(--theme-main);

      &:nth-child(1) {
        fill: var(--theme-main);
      }
    }
  }

  &-tilt,
  &-motion {
    rect {
      fill: transparent;
    }
  }

  &-punch {
    position: absolute;
    top: -14px;
    right: 45px;
    width: 28px;
    height: 28px;
  }

  &-fan,
  &-key {
    position: absolute;
    bottom: 37px;
    z-index: 11;
    width: 24px;
    height: 24px;

    path {
      fill: black;
    }
  }

  &-fan {
    animation: rotate 1s infinite linear;
  }

  &-wrench {
    position: absolute;
    top: 27px;
    width: 16px;
    height: 16px;
    z-index: 100;

    path {
      fill: var(--theme-main);
    }
  }

  &-bottomIndicators {
    position: absolute;
    bottom: -12px;

    .Blink {
      width: 28px;
      height: 28px;

      & + .Blink {
        margin-left: 8px;
      }
    }
  }
}

[theme='dark'] {
  .CarInfo {
    &-fan,
    &-key {
      path {
        fill: white;
      }
    }
  }
}

.CarStateInfo {
  &-active {
    .DeviceInfoIcon-cross {
      display: none;
    }

    path {
      fill: var(--theme-main);
    }
  }
}

.Circles {
  position: absolute;
  bottom: 0;

  ellipse {
    stroke: var(--theme-main);
  }
}

.Car {
  &-element {
    color: #F7F8F9;

    &-active {
      color: #2E2E33;
    }
  }
}

[theme='dark'] {
  .Car {
    &-element {
      color: #2E2E33;

      &-active {
        color: #F7F8F9;
      }
    }

    &-hood {
      path {
        fill: currentColor !important;
      }
    }
  }
}

.CarBody {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: calc(-97px / 2);
  margin-left: -85px;
  z-index: 10;

  &-carBackground {
    fill: var(--app-background) !important;
  }
}

[theme='dark'] {
  .CarBody {
    path {
      fill: white;
    }
  }
}

.Alarm {
  &-alarm {
    path {
      stroke: var(--theme-main);
    }

    &.Alarm-fill {
      path {
        stroke: transparent;
        fill: var(--theme-main);
      }
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
