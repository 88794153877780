@import '../../scss/variables';

.Indicator {
  flex: none;
  width: 6px;
  height: 6px;
  border-radius: 50%;

  &-type {
    &-lock {
      background-color: $green;
    }

    &-engine {
      background-color: $yellow;
    }

    &-doors {
      background-color: $blue;
    }

    &-event {
      background-color: $purple;
    }

    &-alarm {
      background-color: $themeMain;
    }
  }
}

[theme='dark'] {
  .Indicator {
    background-color: white;
  }
}
