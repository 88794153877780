@import "../../scss/variables";

.Datepicker {
  .rdrCalendarWrapper {
    display: flex;
  }

  .DialogActions {
    padding: 0px 10px 6px;
  }

  &-close {
    margin-left: auto;
    color: $themeMain;
  }

  &-submit {
    margin-left: 24px;
    color: #6AD27B;
  }
}
