@import '../../scss/variables';

.HeatTimeSettings {
  &-item {
    cursor: pointer;
    transition: background-color 250ms;

    &:hover {
      background-color: $backgroundColor;
    }
  }
}
