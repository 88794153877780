@import '../../scss/variables';

.TimeSelect {
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  border: 1px solid #EAEEF1;
  border-radius: 4px;
  cursor: pointer;

  &-disabled {
    pointer-events: none;
    background-color: $backgroundColor;
    cursor: default;
  }
}
