@import "../../scss/variables";

.SchedulePage {
  position: relative;
  height: calc(100% - #{$settingsBackButtonHeight});

  &-schedule {
    height: 100%;
    padding-bottom: 52px;
    overflow-y: auto;
  }

  &-saveButton {
    position: absolute;
    left: 16px;
    bottom: 16px;
    width: calc(100% - 32px);
  }
}
