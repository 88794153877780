@import '../../scss/variables';

.LoginPage {
  min-height: 100vh;
  padding: 40px 20px;
  background-color: $backgroundColor;

  &-recoveryLink {
    display: flex;
    align-items: center;
    text-decoration: none;

    .Svg {
      width: 20px;
      height: 20px;
      margin-right: 12px;
    }
  }

  &-link {
    margin-top: 8px;
  }

  &-footer {
    margin-top: 50px;
  }
}
