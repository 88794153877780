.FormElement {
  &-label {
    display: block;
    margin-bottom: 6px;
  }

  > div {
    width: 100%;
  }
}
