.Modal {
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;

  &-transition {
    &-enter {
      opacity: 0;

      &-active {
        opacity: 1;
        transition: opacity 200ms;
      }
    }

    &-exit {
      opacity: 1;

      &-active {
        opacity: 0;
        transition: opacity 200ms;
      }
    }
  }
}
