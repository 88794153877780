@import "../../scss/variables";

.AppSettingsPage {
  .SettingsLink {
    &:first-child {
      border-top: 1px solid $borderColor;
    }
  }

  &-radioGroup {
    display: flex;
    flex-direction: column;
    margin: -10px 0 0 46px;
    padding: 0 0 16px 6px;
    border-bottom: 1px solid $borderColor;

    .Radio {
      margin-top: 20px;
    }
  }
}
