@import '../../scss/variables';

.Select {
  box-sizing: border-box;
  padding: 16px;

  display: inline-flex;

  outline: none;
  border: 1px solid var(--border-color);
  border-radius: 4px;

  transition: background-color 250ms;

  &-label {
    justify-content: space-between;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
  }

  &-icon {
    width: 16px;
    height: 16px;
  }

  &-outlined {
    &:focus,
    &-focus {
      background: var(--background-color);
    }
  }

  &-text {
    justify-content: center;
    border: none;
    padding: 0;
  }

  &-fullWidth {
    width: 100%;
  }

  &-item {
    text-align: center;
    padding: 16px;
    cursor: pointer;
  }
}

.SelectList {
  display: flex;
  flex-direction: column;

  margin-top: 13px;

  background: var(--background-color);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  border: 1px solid var(--border-color);

  &-text {
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
      border: 10px solid transparent;
      border-bottom-color: var(--border-color);
    }

    &:after {
      border-bottom: 10px solid var(--background-color);
      top: -19px;
    }
  }
}
