@import '../../scss/variables';

.DevicesDialog {
  &-content {
    .FormElement {
      & + .FormElement {
        margin-top: 12px;
      }
    }

    .Select {
      &-label {
        span {
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
  }

  &-actions {
    display: flex;
    flex-direction: column;

    button {
      & + button {
        margin-top: 12px;
      }
    }
  }
}
