@import "../../scss/variables";

[theme='dark'] {
  .Logo {
    path {
      fill: white;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        fill: $darkThemeMain;
      }
    }
  }
}
