.CommonInfoPage {
  .FormElement {
    & + .FormElement {
      margin-top: 16px;
    }
  }

  &-button {
    margin-top: 24px;
  }
}
