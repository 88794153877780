@import '../../scss/variables';

.HistoryPage {
  height: 100%;

  .Container {
    padding-top: 24px;
    padding-bottom: 17px;
  }

  &-period {
    margin: 24px 0 0;
  }

  &-eventsList {
    overflow-y: auto;
  }

  &-event {
    height: 48px;

    &-info {
      margin-left: 12px;

      span + span {
        margin-top: 6px;
      }
    }
  }
}

.HistoryPageListFilters {
  flex: none;
  height: 46px;
  border-top: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;

  .HistoryPageListFiltersItem {
    height: 100%;
    border-bottom: 2px solid transparent;

    svg {
      width: 20px;
      height: 20px;
    }

    &-lock {
      path {
        fill: $green;
      }

      &-active {
        border-bottom-color: $green;
      }
    }

    &-engine {
      path {
        fill: $yellow;
      }

      &-active {
        border-bottom-color: $yellow;
      }
    }

    &-doors {
      &-active {
        border-bottom-color: $blue;
      }
    }

    &-event {
      &-active {
        border-bottom-color: $purple;
      }
    }

    &-alarm {
      .DeviceInfoIcon-cross {
        display: none;
      }

      path {
        fill: $themeMain
      }

      &-active {
        border-bottom-color: $themeMain;
      }
    }
  }
}

[theme='dark'] {
  .HistoryPageListFiltersItem {
    path {
      fill: white;
    }

    &-active {
      path {
        fill: var(--theme-main);
      }

      border-bottom-color: var(--theme-main) !important;
    }
  }
}
