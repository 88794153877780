@import '../../scss/variables';

.Input {
  box-sizing: border-box;
  padding: 16px;

  outline: none;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: transparent;
  color: var(--text-main);

  transition: background-color 250ms;

  &-fullWidth {
    width: 100%;
  }

  ::placeholder {
    color: var(--text-second);
  }

  &:focus {
    background: var(--background-color);
  }
}
