@import "../../scss/variables";

.specific-marker {
  position: relative;
  top: -44px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: white;
  z-index: 10000 !important;

  &:hover {
    z-index: 10001 !important;
  }

  &-ball {
    flex: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: black;
    border: 4px solid black;
    border-radius: 50%;
    font-size: 30px;
    font-weight: 900;
    text-transform: uppercase;
  }

  &-time {
    position: absolute;
    top: 4px;
    left: 50%;
    bottom: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    width: 70px;
    padding: 8px;
    background-color: black;
    border-radius: 4px;
    font-size: 16px;
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -8px;
    display: inline-flex;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    background-color: var(--theme-main);
    transform: rotate(45deg);
    z-index: -1;
  }

  &-p {
    .specific-marker-ball {
      border-color: #007AFF;
      background-color: #4EA2FF;
    }

    .specific-marker-time {
      background-color: #007AFF;
    }

    &:after {
      background-color: #007AFF;
    }
  }

  &-s {
    .specific-marker-ball {
      border-color: #D0262E;
      background-color: $themeMain;
    }

    .specific-marker-time {
      background-color: #D0262E;
    }
  }

  &-f {
    .specific-marker-ball {
      border-color: black;
      background-color: #373737;
    }

    &:after {
      background-color: black;
    }
  }
}
