.SettingsPage {
  &-switchLoader {
    justify-content: flex-end;
    margin-left: auto;

    .Switch {
      margin-left: 10px;
    }
  }

  .SettingsLink {
    &-dashcam,
    &-service {
      .SettingsLink-label {
        white-space: nowrap;
      }
    }
  }
}
