.Wrapper {
  padding: 16px;

  &-horizontalPadding {
    padding: 0 16px;
  }

  &-top {
    padding-top: 16px;
  }

  &-botton {
    padding-bottom: 16px;
  }
}
