.Blink {
  &-active {
    animation-name: blinker;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
    animation-duration: 800ms;
  }
}

@keyframes blinker {
  from { opacity: 1.0; }
  to { opacity: 0.0; }
}
