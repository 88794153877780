@import '../../scss/variables';

.Slider {
  &-label {
    font-size: 14px;
    color: var(--text-main);
    margin-bottom: 12px;
  }

  .rc-slider {
    &-rail {
      background-color: var(--border-color);
    }

    &-track {
      background-color: var(--theme-main);
    }

    &-dot {
      border-color: var(--border-color);
      background-color: var(--border-color);

      &-active {
        border-color: var(--theme-main);
        background-color: var(--theme-main);
      }
    }

    &-handle {
      position: relative;
      top: 3px;
      width: 16px;
      height: 8px;
      border-radius: 300px;
      border: none;
      background-color: var(--theme-main);

      &:active {
        box-shadow: none;
      }
    }
  }
}
