@import '../../../../scss/variables';

.EngineBlockButton {
  margin-top: 40px;

  &-active {
    .Button {
      color: var(--theme-main);
      background-color: var(--theme-main-lighten);
      border-color: transparent;

      path {
        fill: var(--theme-main);
      }
    }
  }
}

[theme='dark'] {
  .EngineBlockButton {
    &-active {
      .Button {
        color: var(--text-main);
        border-color: var(--theme-main);

        path {
          fill: var(--text-main);
        }
      }
    }
  }
}
