@import '../../scss/variables';

.Radio {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--text-third);
  cursor: pointer;
  user-select: none;

  &-inputContainer {
    flex: none;
    display: flex;
    position: relative;
    margin-right: 12px;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .Radio-checkMark {
      border-color: var(--theme-main);

      &:after {
        opacity: 1;
      }
    }
  }

  &-checked {
    color: var(--text-main);
  }

  &-checkMark {
    height: 20px;
    width: 20px;
    border: 1px solid var(--border-color);
    border-radius: 50%;
    transition: all 250ms;

    &:after {
      content: "";
      position: absolute;
      opacity: 0;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: var(--theme-main);
      transition: all 250ms;
    }
  }

  &-disabled {
    .Radio-checkMark {
      opacity: 0.5;
    }
  }
}
