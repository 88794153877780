@import "../../scss/variables";

.marker-cluster-custom {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-main);
  border-radius: 50%;
  color: white;
  font-size: 18px;
  font-weight: 700;
}
