@import '../../scss/variables';

.AutostartSettingsPage {
  &-settingsList {
    margin-top: 24px;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);

    .ListItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &-autostartBySchedule {
    margin-top: 16px;
    border-bottom: 0;

    .ListItem {
      border-bottom: none;
      border-top: none;
    }
  }
}
