@import "../../scss/variables";

.ButtonsSettingsPage {
  height: 100%;

  &-saveButton {
    margin: auto 16px 16px;
  }
}

.ButtonsSettingsList-ListItem {
  background-color: var(--app-background);
  user-select: none;
  outline: none;
  cursor: pointer;

  &-active {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25), inset 0px -1px 0px #EBEBEB;
  }

  &:last-child {
    border-bottom: 1px solid var(--border-color);
  }
}

.ButtonsSettingsList-ButtonItem {
  &-icon {
    width: 20px;
    height: 20px;
    margin-right: 16px;
  }

  &-burger {
    width: 20px;
    height: 20px;
    margin-left: auto;
  }

  &-engine {
    .ButtonsSettingsList-ButtonItem-icon {
      display: inline-flex;
      border: 1px solid var(--border-color);
      border-radius: 50%;

      > div {
        width: 18px;
        height: 18px;
        margin: auto;
      }
    }
  }

  &.ButtonsSettingsList-ButtonItem-dashcam {
    path,
    rect {
      fill: #6587FF;
    }
  }
}

.ButtonsSettingsList-GroupLabel {
  padding: 8px 16px;
  border-bottom: 1px solid var(--border-color);
}

[theme='dark'] {
  .ButtonsSettingsList-ButtonItem {
    path,
    rect {
      fill: white;
    }

    &-heat {
      path {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          stroke: white;
        }

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          fill: white;
        }
      }
    }
  }

  .ButtonsSettingsList-GroupLabel {
    span {
      color: $textSecond;
    }
  }
}
