@import "../../scss/variables";

.MapButton {
  flex: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: $textMain;
  cursor: pointer;
  user-select: none;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);

  .Svg {
    flex: none;
    width: 24px;
    height: 24px;

    path {
      fill: white;
    }
  }
}
