.EngineButton {
  position: relative;
  width: 132px;
  height: 132px;
  border-radius: 50%;

  &-circle {
    position: absolute;
    left: 0;
    top: 0;
  }

  &-engineStatusColor {
    width: 12px;
    height: 4px;
    margin-bottom: 5px;
    border-radius: 6px;
    background-color: #FF7A00;

    &-active {
      background-color: #6AD27B;
    }
  }

  &-divider {
    width: 36px;
    height: 1px;
    margin: 5px 0;
    background-color: #E1E1E1;
  }

  &.Button-disabled {
    background-color: transparent;
    opacity: 0.6;
  }

  &.EngineButton-small {
    width: 56px;
    height: 56px;

    span {
      font-size: 12px;
    }

    .EngineButton {
      &-status,
      &-divider {
        display: none;
      }
    }
  }
}

[theme='dark'] {
  .EngineButton {
    &-engineStatusColor {
      background-color: white;

      &-active {
        background-color: var(--theme-main);
      }
    }
  }
}
