@import '../../scss/variables';

.ConditionValue {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  cursor: pointer;

  &-value {
    display: inline-flex;
    align-items: center;
  }

  &-item {
    cursor: pointer;
    transition: background-color 250ms;

    &:hover {
      background-color: $backgroundColor;
    }
  }
}
