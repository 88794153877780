@import '../../scss/variables';

.DialogList {
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  cursor: pointer;

  &-item {
    cursor: pointer;
    transition: background-color 250ms;
    color: var(--text-main);

    &:hover {
      background-color: var(--background-color);
    }
  }
}
