.ScheduleDay {
  &-title {
    display: inline-block;
    margin-bottom: 16px;
  }

  &-dayTime {
    padding: 8px 0;
  }

  &-timeHint {
    margin-top: 6px;
  }

  &-timeSelect {
    pointer-events: none;
    color: var(--text-main);

    .Select {
      .Select-label {
        span {
          color: inherit;
          font-weight: 400;
        }
      }
    }
  }

  &-timeSelect-enabled {
    pointer-events: auto;
    color: var(--theme-main);
  }

  &-timeSelectPopover {
    .SelectList {
      margin-top: 0;
      max-height: 300px;
      overflow-y: auto;
      padding-right: 14px;

      &:before {
        display: none;
      }

      .Select-item {
        padding: 4px 8px;
      }
    }
  }
}
