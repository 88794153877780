@import '../../scss/variables';

.Switch {
  flex: none;
  position: relative;
  width: 32px;
  height: 20px;
  border-radius: 50px;
  border: 1px solid var(--border-color);
  transition: all 250ms;

  label {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &-indicator {
    position: absolute;
    left: 3px;
    bottom: 3px;
    width: 14px;
    height: 14px;
    background-color: var(--text-second);
    border-radius: 50%;
    transition: all 250ms;
  }

  &-input {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 4px;
    opacity: 0;
  }

  &-checked {
    background-color: var(--theme-main);
    border-color: var(--theme-main);

    .Switch-indicator {
      left: calc(100% - 17px);
      background-color: var(--app-background);
    }
  }

  &-disabled {
    background-color: var(--border-color);
    border-color: var(--border-color);
  }
}
