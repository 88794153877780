@import '~react-date-range/dist/styles.css';
@import '~react-date-range/dist/theme/default.css';

@import '../../scss/variables';

.RangeDatePicker {
  &-input {
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    cursor: pointer;
    color: var(--text-main);
  }

  &-icon {
    width: 16px;
    height: 16px;
  }
}

.RangeDatePickerDialog {
  .DialogActions {
    padding: 0 10px 6px;
  }

  .Button {
    font-weight: 500;
  }

  &-today {
    color: var(--text-main);
  }

  &-cancel {
    margin-left: auto;
    color: var(--theme-main);
  }

  &-submit {
    margin-left: 20px;
    color: #6AD27B;
  }

  .rdrDateDisplayWrapper {
    background-color: var(--background-color);
  }

  .rdrCalendarWrapper {
    background-color: var(--app-background);
  }
}
