.AuthForm {
  width: 100%;
  max-width: 392px;
  padding: 48px 32px;
  margin: 40px 0;

  &-title {
    margin: 0 0 16px;
  }

  &-form {
    width: 100%;
  }

  .FormElement {
    & + .FormElement {
      margin-top: 12px;
    }
  }

  &-submitButton {
    margin-top: 24px;
  }

  &-link {
    margin-top: 8px;
  }
}
