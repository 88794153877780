@import '../../scss/variables';

.Typography {
  &-color {
    &-main {
      color: var(--text-main);
    }

    &-second {
      color: var(--text-second);
    }

    &-third {
      color: var(--text-third);
    }

    &-red {
      color: var(--theme-main);
    }
  }

  &-textDecoration {
    &-none {
      text-decoration: none;
    }
  }

  &-size {
    @for $i from 10 through 24 {
      &-#{$i} {
        font-size: #{$i}px;
      }
    }
  }

  &-weight {
    @for $i from 400 through 700 {
      &-#{$i} {
        font-weight: $i;
      }
    }
  }

  &-align {
    &-left {
      text-align: left;
    }

    &-center {
      text-align: center;
    }

    &-right {
      text-align: right;
    }
  }
}
