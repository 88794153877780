.SettingsPhonesPage {
  height: 100%;
  padding-bottom: 16px;

  .Wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .FormElement {
    & + .FormElement {
      margin-top: 16px;
    }
  }

  .Button {
    margin-top: auto;
  }
}