@import '../../scss/variables';

.Button {
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 0 16px;
  height: 36px;

  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 600;

  outline: none;
  border: none;
  border-radius: 4px;
  background: var(--theme-main);
  color: #fff;

  transition: background-color 250ms;

  cursor: pointer;

  &-startIcon {
    width: 18px;
    height: 18px;
    margin-right: 12px;
  }

  &-endIcon {
    width: 18px;
    height: 18px;
    margin-left: 12px;
  }

  &-startIcon,
  &-endIcon {
    color: inherit;

    path {
      fill: currentColor;
    }
  }

  &-fullWidth {
    width: 100%;
  }

  &-contained:not(.Button-disabled) {
    &:hover {
      background-color: var(--theme-main-darken);
    }

    &Second {
      background-color: var(--text-third);

      &:hover {
        background-color: var(--text-third-darken);
      }
    }
  }

  &-text {
    height: auto;
    background-color: transparent;
    padding: 0;
    border-radius: 0;
  }

  &-outlined {
    border: 1px solid var(--border-color);
    border-radius: 4px;
    background-color: transparent;
    font-weight: 500;
    color: var(--text-second);
  }

  &-size {
    &-middle {
      height: 48px;
      font-size: 16px;
      font-weight: bold;
    }

    &-large {
      height: 64px;
      font-size: 18px;
      font-weight: 500;
    }
  }

  &-disabled {
    background-color: var(--text-second);
    cursor: default;
  }

  &-loading {
    cursor: progress;
  }

  .Spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    background-color: rgba(var(--theme-main), .6);
  }
}
