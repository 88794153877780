@import "../../scss/variables";

.DeviceCard {
  position: relative;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 16px;
  cursor: pointer;

  &-column {
    > div {
      display: flex;
      flex-direction: column;

      & + div {
        margin-top: 12px;
      }

      span {
        white-space: nowrap;

        & + span {
          margin-top: 6px;
        }
      }
    }
  }

  &-rightColumn {
    position: absolute;
    bottom: 16px;
    right: 16px;
    top: 16px;

    > div {
      display: flex;
      align-items: center;

      .Svg {
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
    }
  }

  &-button {
    color: var(--text-second);

    path {
      stroke: var(--text-second);
    }

    &-active {
      color: var(--theme-main);

      path {
        stroke: var(--theme-main);
      }
    }
  }

  & + .DeviceCard {
    margin-top: 16px;
  }
}
