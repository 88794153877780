.NavBarLink {
  width: 100%;
  height: 56px;
  text-decoration: none;

  transition: border-bottom-color 250ms;

  &-content {
    height: 100%;
  }

  &-label {
    margin-top: 6px;
    transition: color 250ms;
  }

  &-icon {
    width: 20px;
    height: 20px;

    path {
      transition: 250ms;
    }
  }

  &-active {
    .Svg {
      path {
        fill: var(--theme-main);
      }
    }

    .NavBarLink-label {
      color: var(--theme-main);
    }
  }
}
