@import "../../scss/variables";

.SettingsBack {
  text-decoration: none;
  height: $settingsBackButtonHeight;
  padding: 0 16px;

  .Svg {
    width: 24px;
    height: 24px;
  }
}

[theme='dark'] {
  .SettingsBack {
    path {
      stroke: var(--text-main);
    }
  }
}
