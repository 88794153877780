@import "~react-leaflet-markercluster/dist/styles.min.css";

.TrackingMap {
  &-buttonsContainer {
    position: absolute;
    bottom: 24px;
    right: 24px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
  }

  &-button {
    & + .TrackingMap-button {
      margin-top: 24px;
    }
  }

  .leaflet-popup-content-wrapper {
    background-color: var(--background-color);
    color: white;
  }
}
