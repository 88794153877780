.App {
  &-content {
    flex: none;
    width: 320px;
    height: 100vh;
    box-shadow: 12px 0 24px rgba(0, 0, 0, 0.08);

    main {
      flex: 1;
      overflow: hidden;
    }
  }
}
