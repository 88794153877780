@import '../../scss/variables';

.AlertTemplate {
  color: #fff;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24);

  .Button {
    margin-left: 32px;
  }

  &-type {
    &-success {
      background-color: $themeMain;
    }

    &-error {
      background-color: #FF7878;
    }
  }
}

[theme='dark'] {
  .AlertTemplate {
    background-color: var(--theme-main);
  }
}
