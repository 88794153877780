@-moz-document url-prefix("https://www.openstreetmap.org")
{
  ::-webkit-scrollbar
  {
    overflow: visible;
    background: #282828;
    border-color: #444;
  }

  ::-webkit-scrollbar-track
  {
    background-clip: padding-box;
    border: solid transparent;
    border-width: 0;
  }

  ::-webkit-scrollbar-thumb
  {
    background-color: rgba(238,238,238,.4);
    background-clip: padding-box;
    border: solid transparent;
    border-width: 3px;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb:hover, ::-webkit-scrollbar-thumb:active
  {
    background-color: rgba(238,238,238,.5);
  }

  ::-webkit-scrollbar-corner
  {
    background: #282828;
  }

  body
  {
    background-color: #222;
    color: #eee;
  }

  a:visited, a
  {
    color: hsl(226,100%,80%);
  }

  a:hover
  {
    color: hsl(226,100%,85%);
  }

  /* ===== Header ===== */
  body.small-nav header
  {
    background: #222;
  }

  header h1 a
  {
    color: #eee !important;
  }

  nav.primary .disabled .btn-outline-primary:hover
  {
    background-color: #1f4018;
  }

  nav.primary .disabled .btn-outline-primary
  {
    color: #417a33;
  }

  .dropdown-menu
  {
    background-color: #333;
    border-color: rgba(255,255,255,.15);
  }

  a.dropdown-item
  {
    color: #eee;
  }

  nav.primary .dropdown-item:hover, nav.primary .dropdown-item:active, nav.secondary .dropdown-item:hover, nav.secondary .dropdown-item:active
  {
    background-color: #6fa462;
  }

  .dropdown-item:hover, .dropdown-item:focus
  {
    background-color: #555;
    color: #eee;
  }

  .dropdown-divider
  {
    border-top-color: #444;
  }

  .count-number
  {
    background: #3a662f;
    color: #eee;
  }

  nav.secondary .nav-link
  {
    color: #ccc;
  }

  nav.secondary .nav-link:hover
  {
    color: #eee;
  }

  nav.secondary > ul li.current a
  {
    color: #eee;
    font-weight: bold;
  }

  nav.secondary .login-menu .btn-outline-secondary
  {
    border-color: #444;
    color: #ccc;
  }

  nav.secondary .login-menu .btn-outline-secondary:hover
  {
    background-color: #333;
    border-color: #444;
    color: #eee;
  }

  nav.secondary .user-menu .btn-outline-secondary
  {
    border-color: #444;
    color: #ccc;
  }

  nav.secondary .user-menu .btn-outline-secondary:hover
  {
    background-color: #333;
    border-color: #444;
    color: #eee;
  }

  nav.secondary .user-menu .btn-outline-secondary:focus
  {
    background-color: #333;
  }

  nav.secondary .user-menu .btn-outline-secondary:not(:disabled):not(.disabled):active, nav.secondary .user-menu .btn-outline-secondary.active:not(:disabled):not(.disabled), .show > nav.secondary .user-menu .btn-outline-secondary.dropdown-toggle
  {
    background-color: #333;
    border-color: #444;
    color: #eee;
  }

  nav.secondary .user-menu.show .btn-outline-secondary
  {
    background-color: #333;
  }

  nav.secondary img.user_thumbnail_tiny
  {
    opacity: .8;
  }

  nav.secondary #inboxanchor
  {
    background-color: #555;
  }

  #menu-icon
  {
    filter: invert(1);
  }

  /* ===== Body ===== */
  /*[[mapbrightness]]*/

  .leaflet-contextmenu
  {
    background-color: #333;
  }

  .leaflet-contextmenu a.leaflet-contextmenu-item
  {
    color: #eee;
    cursor: pointer;
  }

  .leaflet-contextmenu a.leaflet-contextmenu-item.over
  {
    background-color: #444;
    border-color: #484848;
  }

  .leaflet-popup-content-wrapper, .leaflet-popup-tip
  {
    background: #333;
    color: #eee;
  }

  .leaflet-container a.leaflet-popup-close-button
  {
    color: #999;
  }

  .leaflet-container a.leaflet-popup-close-button:hover
  {
    color: #ccc;
  }

  .leaflet-control-scale-line
  {
    background: rgba(34,34,34,.5);
    border-color: #aaa;
  }

  .leaflet-control-scale-line:not(:first-child)
  {
    border-top-color: #aaa;
  }

  .leaflet-control-attribution, .leaflet-control-scale-line
  {
    color: #eee;
  }

  .leaflet-container .leaflet-control-attribution
  {
    background: rgba(34,34,34,.7);
  }

  .leaflet-container a
  {
    color: #a3b9ff;
  }

  .donate-attr
  {
    color: #7ebc6f !important;
  }

  /* ===== Left Panel ===== */
  /* Sidebar */
  .map-layout #sidebar
  {
    background: #222;
  }

  #sidebar .loader
  {
    filter: invert(1) contrast(73%);
  }

  .icon.close
  {
    background-position: -200px -20px;
  }

  .close-wrap:hover .icon.close, .icon.close:hover
  {
    background-position: -200px 0;
  }

  .map-layout .welcome .button.learn-more
  {
    border-right-color: #444;
  }

  /* Search Panel */
  .search_form
  {
    background-color: #222;
  }

  .standard-form .search_form, .standard-form .directions_form
  {
    background-color: #333;
  }

  .standard-form input[type="text"], .standard-form input[type="email"], .standard-form input[type="url"], .standard-form input[type="password"], .standard-form textarea
  {
    background-color: #222;
    border-color: #444;
    color: #eee;
  }

  .standard-form .search_form .describe_location, .standard-form .directions_form .describe_location
  {
    color: hsl(226,100%,80%);
  }

  .standard-form input[type="button"], .standard-form input[type="submit"], .standard-form input[type="reset"], .standard-form a.button
  {
    background: hsl(226,50%,50%);
  }

  .standard-form input[type="button"]:hover, .standard-form input[type="submit"]:hover, .standard-form input[type="reset"]:hover, .standard-form a.button:hover
  {
    background: hsl(226,50%,55%);
  }

  .list-group-item
  {
    background-color: #222;
    border-color: rgba(255,255,255,.125);
  }

  #sidebar .search_results_entry ul li.selected
  {
    background: hsl(60,20%,22%);
  }

  /* What is here? */
  #sidebar_content .query-results ul li.selected
  {
    background: hsl(60,20%,22%);
  }

  /* Obj Info*/
  #sidebar_content .browse-section
  {
    border-bottom-color: #444;
  }

  #sidebar_content .browse-tag-list
  {
    background-color: hsl(226,10%,15%);
    border-color: #444;
  }

  #sidebar_content .browse-tag-list th, #sidebar_content .browse-tag-list td
  {
    border-bottom-color: #444;
  }

  #sidebar_content .browse-tag-list .browse-tag-k
  {
    background-color: hsl(226,10%,15%);
  }

  #sidebar_content .browse-tag-list .browse-tag-v
  {
    background-color: #222;
    border-left-color: #444;
  }

  /* Routing */
  .standard-form .search_form input[type=text], .standard-form .directions_form input[type=text]
  {
    transition: .1s;
  }

  .standard-form .search_form input:focus, .standard-form .directions_form input:focus
  {
    border-color: #666;
    box-shadow: 0 0 0 1px #666;
  }

  select
  {
    background: #222;
    border-color: #444;
    border-radius: 3px;
    color: #eee;
  }

  tr.turn:hover
  {
    background: hsl(60,20%,22%);
  }

  div.direction
  {
    filter: invert(1);
  }

  td.instruction, td.distance
  {
    border-color: #444;
  }

  /* Add Note*/
  .alert-info
  {
    background-color: hsl(189,20%,20%);
    border-color: hsl(189,20%,25%);
    color: hsl(189,78%,80%);
  }

  /* History */
  .text-dark
  {
    color: #eee !important;
  }

  a.text-dark:hover, a.text-dark:focus
  {
    color: #eee !important;
  }

  #sidebar .changesets .comments
  {
    color: #aaa;
  }

  #sidebar .changesets li.selected
  {
    background: hsl(60,20%,22%);
  }

  /* Export */
  .export_form .export_boxy
  {
    background: #333;
    border-color: #444;
  }

  /* ===== Right Panel ===== */
  .leaflet-control .control-button.active
  {
    background-color: #6fa462;
  }

  .leaflet-control .control-button.disabled, .leaflet-control .control-button.leaflet-disabled
  {
    opacity: .5;
  }

  .map-layout #map-ui
  {
    background: #222;
    scrollbar-color: #6e6e6e #222;
    scrollbar-width: thin;
  }

  .map-layout #map-ui .section
  {
    border-bottom-color: #444;
  }

  .layers-ui .base-layers li
  {
    border-color: #222;
  }

  .layers-ui .base-layers li:hover
  {
    border-color: #666;
  }

  .layers-ui .base-layers li.active
  {
    border-color: #4d863f;
  }

  .layers-ui .base-layers li.active:hover
  {
    border-color: #60a94f;
  }

  .layers-ui .base-layers li label
  {
    background: rgba(34,34,34,.9);
  }

  input[type="checkbox"]
  {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #222 !important;
    border: 1px solid #666 !important;
    border-radius: 2px !important;
    height: 13px;
    width: 13px;
  }

  input[type="checkbox"]:checked
  {
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="-40 -40 592 592"><path fill="%23222" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"/></svg>') no-repeat, hsl(226,100%,80%) !important;
    border-color: hsl(226,100%,80%) !important;
  }

  input[type="checkbox"]:indeterminate
  {
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="-50 0 448 512"><path fill="%2399b1ff" d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>') no-repeat, #222 !important;
    border-color: #666 !important;
  }

  .share-ui .share-tabs a
  {
    background-color: hsl(226,50%,30%);
    border-right-color: #222;
  }

  .share-ui .share-tabs a:first-child
  {
    border-right-color: #222;
  }

  .share-ui .share-tabs a.active
  {
    background-color: hsl(226,50%,50%);
  }

  .text-muted
  {
    color: #999 !important;
  }

  /* ===== Traces ===== */
  .content-heading
  {
    background: #333;
  }

  ul.secondary-actions>li
  {
    border-left-color: #444;
  }

  .page-link
  {
    background-color: #222;
    border-color: #444;
    color: hsl(226,100%,80%);
  }

  .page-link:hover
  {
    background-color: #333;
    border-color: #444;
    color: hsl(226,100%,85%);
  }

  .page-item.disabled .page-link
  {
    background-color: #222;
    border-color: #444;
    color: #999;
  }

  .table
  {
    color: #eee;
  }

  .table-striped>tbody>tr:nth-child(2n+1)>td, .table-striped>tbody>tr:nth-child(2n+1)>th
  {
    background-color: #282828;
  }

  .text-danger
  {
    color: hsl(354,70%,60%) !important;
  }

  .table-striped tbody tr img
  {
    filter: invert(1) contrast(73%);
  }

  .table-striped tbody tr:nth-of-type(odd) img
  {
    filter: invert(1) contrast(69%);
  }

  .traces-show .content-inner img
  {
    filter: invert(1) contrast(73%);
  }

  .badge-success
  {
    background-color: #3a662f;
  }

  /* Traces New */
  .custom-file-label
  {
    background-color: #222;
    border-color: #444;
    color: #eee;
  }

  .custom-file-label::after
  {
    background-color: #444;
    color: #eee;
  }

  .form-control
  {
    background-color: #222;
    border-color: #444;
    color: #eee;
  }

  .form-control:focus
  {
    background-color: #222;
    border-color: #666;
    box-shadow: 0 0 0 0.2rem rgba(112,146,255,.5);
    color: #eee;
  }

  .text-muted a
  {
    color: hsl(226,100%,80%);
  }

  .text-muted a:hover
  {
    color: hsl(226,100%,85%);
  }

  a.btn-primary
  {
    color: #fff;
  }

  .btn-primary
  {
    background-color: hsl(226,50%,50%);
    border-color: hsl(226,50%,50%);
  }

  .btn-primary:hover
  {
    background-color: hsl(226,50%,55%);
    border-color: hsl(226,50%,55%);
  }

  .btn-link
  {
    color: hsl(226,100%,80%);
  }

  .btn-link:hover
  {
    color: hsl(226,100%,85%);
  }

  /* ===== Diary ===== */
  a[href="/diary/new"] img
  {
    filter: brightness(8) hue-rotate(-20deg);
  }

  .diary_post
  {
    border-top-color: #444;
  }

  img.user_thumbnail, img.user_image
  {
    border-color: #444;
    opacity: .8;
    transition: .2s ease-out;
  }

  img.user_thumbnail:hover, img.user_image:hover
  {
    opacity: 1;
  }

  code
  {
    color: hsl(332,79%,70%);
  }

  .richtext code, .prose code
  {
    background: #333;
  }

  pre
  {
    color: #eee;
  }

  .richtext pre, .prose pre
  {
    background: #333;
  }

  .richtext blockquote, .prose blockquote
  {
    border-left-color: hsl(226,15%,20%);
    color: #aaa;
  }

  .richtext img, .prose img
  {
    background-color: hsl(226,15%,20%);
  }

  .diary_post .richtext img
  {
    opacity: .8;
    transition: .2s ease-out;
  }

  .diary_post .richtext img:hover
  {
    opacity: 1;
  }

  /* Post Comments */
  .diary_entries .diary-comment
  {
    border-top-color: #444;
  }

  .diary_entries .diary-comment:first-child
  {
    border-top-color: #444;
  }

  .diary_entries #newcomment
  {
    border-top-color: #444;
  }

  .richtext_container .richtext_help
  {
    background-color: hsl(226,15%,20%);
  }

  .richtext_container .richtext_help h4.heading, .richtext_container .richtext_help li
  {
    border-bottom-color: #444;
  }

  .richtext_container .richtext_content .richtext_preview
  {
    background-color: hsl(226,15%,20%);
  }

  .standard-form input.deemphasize[type="button"], .standard-form input.deemphasize[type="submit"], .standard-form input.deemphasize[type="reset"], .standard-form a.button.deemphasize
  {
    background: hsl(226,50%,30%);
  }

  .standard-form input.deemphasize[type="button"]:hover, .standard-form input.deemphasize[type="submit"]:hover, .standard-form input.deemphasize[type="reset"]:hover, .standard-form a.button.deemphasize:hover
  {
    background: hsl(226,50%,40%);
  }

  .buttons input[type="submit"], .buttons input[type="button"], .buttons input[type="reset"], .buttons .button, .buttons .button_to
  {
    border-right-color: #222;
  }

  .standard-form input[type="button"]:disabled, .standard-form input[type="submit"]:disabled, .standard-form input[type="reset"]:disabled, .standard-form a.button:disabled
  {
    background: #333;
    color: #999;
  }

  .standard-form input[type="button"]:disabled:hover, .standard-form input[type="submit"]:disabled:hover, .standard-form input[type="reset"]:disabled:hover, .standard-form a.button:disabled:hover
  {
    background: #333;
  }

  /* ===== Help ===== */
  .card
  {
    background-color: #222;
    border-color: rgba(255,255,255,.125);
  }

  .card-footer
  {
    background-color: rgba(255,255,255,.03);
    border-top-color: rgba(255,255,255,.125);
  }

  /* ===== About ===== */
  .site-about #content
  {
    background-color: #181818;
  }

  .site-about #content .attr .byosm
  {
    background: #3a662f;
  }

  .site-about #content .attr .user-image
  {
    opacity: .8;
  }

  .site-about #content .text
  {
    background: #222;
  }

  .site-about #content .content-inner
  {
    color: #eee;
  }

  /* ===== Messages ===== */
  .table th, .table td
  {
    border-top-color: #444;
  }

  .table thead th
  {
    border-bottom-color: hsl(210,15%,30%);
  }

  .messages .inbox-row
  {
    background: hsl(226,15%,20%);
  }

  .messages .inbox-row-unread
  {
    background: #3a662f;
  }

  img.user_thumbnail_tiny
  {
    border-color: #444;
  }

  .info-line
  {
    border-bottom-color: #444;
  }

  /* ===== Profile ===== */
  hr
  {
    border-top-color: rgba(255,255,255,.1);
  }

  .content_map
  {
    border-color: #444;
  }

  /* Profile Settings */
  .standard-form .form-divider
  {
    border-top-color: #444;
  }

  input[type="radio"]
  {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #222 !important;
    border-radius: 50%;
    border: 1px solid #666;
    height: 14px !important;
    width: 14px !important;
  }

  input[type="radio"]:checked
  {
    border-color: hsl(212,100%,70%);
    box-shadow: inset 0 0 0 2px #222, inset 0 0 0 6px hsl(212,100%,70%);
  }

  /* ==== Edit ==== */
  .ideditor ::-webkit-scrollbar
  {
    background: #222;
    border-left-color: #444;
  }

  .ideditor ::-webkit-scrollbar-thumb
  {
    background-color: rgba(238,238,238,.4);
  }

  .ideditor ::-webkit-scrollbar-thumb:hover, .ideditor ::-webkit-scrollbar-thumb:active
  {
    background-color: rgba(238,238,238,.5);
  }

  .ideditor
  {
    color: #eee;
  }

  /* Left Panel */
  .ideditor .sidebar
  {
    background: #181818;
    border-color: #444;
  }

  .ideditor .header
  {
    border-bottom-color: #444;
  }

  .ideditor .fillL
  {
    background: #222;
    color: #eee;
  }

  .ideditor button
  {
    background: #222;
    color: #eee;
  }

  .ideditor button:hover
  {
    background-color: #333;
  }

  .ideditor button:focus, .ideditor button:active
  {
    background-color: #333;
  }

  .ideditor textarea, .ideditor input[type=text], .ideditor input[type=search], .ideditor input[type=number], .ideditor input[type=url], .ideditor input[type=tel], .ideditor input[type=email]
  {
    background-color: #222;
    border-color: #444;
    color: #eee;
  }

  /* Ideditor Hovered */
  .ideditor .inspector-hover .form-field-input-radio label.active, .ideditor .inspector-hover .entity-editor-pane a.hide-toggle
  {
    color: #ccc;
  }

  .ideditor .preset-list-button-wrap
  {
    border-color: #444;
  }

  .ideditor .inspector-hover .preset-list-button, .ideditor .inspector-hover .tag-row input
  {
    background: #181818;
  }

  .ideditor .preset-list-button .label
  {
    background-color: #181818;
    border-left-color: rgba(255,255,255,.1);
  }

  .ideditor .section .grouped-items-area
  {
    background: #222;
  }

  .ideditor .field-label
  {
    border-color: #444;
    color: #eee;
  }

  .ideditor .inspector-hover .section-entity-issues .issue-container .issue .issue-label, .ideditor .inspector-hover .form-field-input-wrap .label, .ideditor .inspector-hover .form-field-input-multicombo .chiplist, .ideditor .inspector-hover .form-field-button, .ideditor .inspector-hover .structure-extras-wrap, .ideditor .inspector-hover .comments-container .comment, .ideditor .inspector-hover button, .ideditor .inspector-hover input, .ideditor .inspector-hover textarea, .ideditor .inspector-hover label
  {
    background: #222;
  }

  .ideditor .inspector-hover .section-entity-issues .issue-container *
  {
    border-color: #444 !important;
  }

  .ideditor .label-text .label-textannotation svg.icon
  {
    color: #eee;
  }

  .ideditor .form-field-input-localized button.localized-add.disabled, .ideditor .form-field-input-localized input.localized-main.disabled, .ideditor .form-field-input-localized input.localized-lang.disabled, .ideditor .form-field-input-localized input.localized-value.disabled
  {
    background-color: #222;
    color: #999;
  }

  .ideditor .form-field-button
  {
    border-color: #444;
  }

  .ideditor .localized-multilingual .entry::before
  {
    background: #444;
  }

  .ideditor .form-field-input-address
  {
    border-color: #444;
  }

  .ideditor .form-field-input-check
  {
    border-color: #444;
  }

  .ideditor .form-field ul.rows
  {
    border-color: #444;
  }

  .ideditor .form-field-input-wrap>input, .ideditor .form-field-input-wrap>label, .ideditor .form-field-input-wrap>textarea, .ideditor .form-field-input-wrap>ul.chiplist
  {
    border-color: #444;
  }

  /* Ideditor */
  .ideditor a, .ideditor a:visited, .ideditor a:active
  {
    color: hsl(226,100%,80%);
  }

  .ideditor a:hover
  {
    color: hsl(226,100%,85%);
  }

  /* Type Obj */
  .ideditor .preset-icon-fill-vertex circle
  {
    fill: #222;
    stroke: #eee;
  }

  .ideditor .preset-list-button:hover .label
  {
    background-color: #333;
  }

  .ideditor .preset-list-button-wrap button.tag-reference-button
  {
    background: #181818;
  }

  .ideditor[dir='ltr'] .preset-list-button-wrap button.tag-reference-button
  {
    border-left-color: #444;
  }

  .ideditor .preset-list-button-wrap button.tag-reference-button:hover
  {
    background: #333;
  }

  .ideditor .preset-list-button:focus .label, .ideditor .preset-list-button:active .label, .ideditor .preset-list-button.disabled, .ideditor .preset-list-button.disabled .label
  {
    background-color: #333;
  }

  .ideditor .category .preset-list-button:after, .ideditor .category .preset-list-button:before
  {
    border-color: #444;
  }

  .ideditor .subgrid .arrow
  {
    border-bottom-color: #222;
  }

  .ideditor .fillL3
  {
    background: #222;
    color: #eee;
  }

  .ideditor .current .preset-list-button, .ideditor .current .preset-list-button .label
  {
    background-color: hsl(232, 15%, 25%);
  }

  /* Issues */
  .ideditor .section-entity-issues .issue-container .issue
  {
    background: #222;
    border-color: #444;
  }

  .ideditor .warnings-list, .ideditor .warnings-list *, .ideditor .issue-container.active .issue.severity-warning, .ideditor .issue-container.active .issue.severity-warning *
  {
    border-color: hsl(45,40%,35%);
  }

  .ideditor .issue-container.active .issue.severity-warning .issue-label
  {
    background: hsl(45,40%,30%);
  }

  .ideditor .issue.severity-warning .issue-icon
  {
    color: hsl(45,100%,60%);
  }

  .ideditor .issue.severity-warning .issue-fix-item.actionable, .ideditor .issue-container.active .issue.severity-warning .issue-info-button
  {
    color: hsl(45,100%,60%);
    fill: hsl(45,100%,60%);
  }

  .ideditor .issue.severity-warning .issue-fix-item.actionable:hover, .ideditor .issue-container.active .issue.severity-warning .issue-info-button:hover
  {
    color: hsl(45,100%,90%);
    fill: hsl(45,100%,90%);
  }

  .ideditor li.issue-fix-item:not(.actionable) .fix-icon
  {
    color: #ccc;
    fill: #ccc;
  }

  .ideditor .warnings-list .issue.severity-warning .issue-label, .ideditor .issue.severity-warning .issue-fix-list, .ideditor.mode-save .warning-section
  {
    background: hsl(45,40%,20%);
  }

  .ideditor .warnings-list .issue.severity-warning .issue-label:hover, .ideditor .issue.severity-warning .issue-fix-item.actionable:hover
  {
    background: hsl(45,40%,25%);
  }

  .ideditor .errors-list, .ideditor .errors-list *, .ideditor .issue-container.active .issue.severity-error, .ideditor .issue-container.active .issue.severity-error *
  {
    border-color: hsl(0,40%,35%);
  }

  .ideditor .issue-container.active .issue.severity-error .issue-label
  {
    background: hsl(0,40%,30%);
  }

  .ideditor .issue.severity-error .issue-icon
  {
    color: hsl(0,100%,70%);
  }

  .ideditor .issue.severity-error .issue-fix-item.actionable, .ideditor .issue-container.active .issue.severity-error .issue-info-button
  {
    color: hsl(0,100%,70%);
    fill: hsl(0,100%,70%);
  }

  .ideditor .issue.severity-error .issue-fix-item.actionable:hover, .ideditor .issue-container.active .issue.severity-error .issue-info-button:hover
  {
    color: hsl(0,100%,90%);
    fill: hsl(0,100%,90%);
  }

  .ideditor .errors-list .issue.severity-error .issue-label, .ideditor .issue.severity-error .issue-fix-list, .ideditor.mode-save .error-section
  {
    background: hsl(0,40%,20%);
  }

  .ideditor .errors-list .issue.severity-error .issue-label:hover, .ideditor .issue.severity-error .issue-fix-item.actionable:hover
  {
    background: hsl(0,40%,25%);
  }

  /* Preset Fields */
  .ideditor .field-label
  {
    background: #333;
  }

  .ideditor .field-label button
  {
    background: #333;
    border-left-color: #444;
    fill: #eee;
  }

  .ideditor .field-label button:hover
  {
    background: #444;
  }

  .ideditor .form-field-button
  {
    background-color: #333;
  }

  .ideditor .form-field-button:hover
  {
    background-color: #444;
  }

  .ideditor .form-field-input-check
  {
    background: #222;
    color: hsl(226,100%,80%);
  }

  .ideditor .form-field-input-check:hover
  {
    background: #333;
  }

  .ideditor .form-field-input-check>.reverser.button
  {
    background-color: hsl(218,15%,25%);
    border-color: hsl(218,15%,30%);
  }

  .ideditor .form-field-input-multicombo ul.chiplist
  {
    background: #222;
  }

  .ideditor .form-field-input-multicombo li.chip
  {
    background-color: hsl(218,15%,25%);
    border-color: hsl(218,15%,30%);
  }

  .ideditor .form-field-input-multicombo .input-wrap
  {
    border-color: #444;
  }

  .ideditor .form-field ul.rows li
  {
    border-top-color: #444;
  }

  .ideditor .form-field-input-wrap .label
  {
    background: #181818;
  }

  .ideditor .form-field-input-radio>label
  {
    background-color: #222;
    color: hsl(226,100%,80%);
  }

  .ideditor .form-field-input-radio>label:not(:last-of-type)
  {
    border-bottom-color: #444;
  }

  .ideditor .form-field-input-radio>label:hover
  {
    background-color: #333;
  }

  .ideditor .form-field-input-radio>label:active
  {
    background-color: #333;
  }

  .ideditor .form-field-input-radio>label.active
  {
    background-color: hsl(232,15%,25%);
  }

  .ideditor .structure-extras-wrap
  {
    background: #222;
    border-color: #444;
  }

  /* Tags */
  .ideditor button.raw-tag-option
  {
    background: #666;
  }

  .ideditor .tag-row input.key
  {
    background-color: #181818;
  }

  .ideditor .tag-row:first-child input.key
  {
    border-top-color: #444;
  }

  .ideditor .tag-row input.value
  {
    border-right-color: #444;
  }

  .ideditor .tag-row:first-child input.value
  {
    border-top-color: #444;
  }

  .ideditor .tag-row button
  {
    border-color: #444;
  }

  .ideditor .tag-row button:hover
  {
    background: #444;
  }

  .ideditor .form-field-button .icon
  {
    fill: #eee;
  }

  .ideditor div.combobox
  {
    box-shadow: 0 4px 10px 1px rgba(0,0,0,0.2);
    background: #333;
    border-color: #444;
  }

  .ideditor .combobox a
  {
    border-color: #444;
  }

  .ideditor .combobox a.selected,.ideditor .combobox a:active
  {
    background: #444;
  }

  .ideditor .combobox a:hover
  {
    background: #444;
  }

  .ideditor .combobox-caret::after
  {
    border-top-color: #444;
  }

  .ideditor .add-row button
  {
    background: rgba(255,255,255,.1);
  }

  .ideditor .add-row button:hover
  {
    background: rgba(255,255,255,.2);
  }

  .ideditor .footer
  {
    background-color: #222;
    border-top-color: #444;
  }

  /* Save Panel */
  .ideditor textarea:active, .ideditor input:active, .ideditor textarea:focus, .ideditor input:focus
  {
    background-color: #333;
  }

  .ideditor button.secondary-action
  {
    background: #333;
  }

  .ideditor button.secondary-action:hover
  {
    background: #444;
  }

  .ideditor button.secondary-action:focus, .ideditor button.secondary-action:active
  {
    background: #444;
  }

  .ideditor button.action.disabled, .ideditor button.action[disabled]
  {
    background: #333;
    color: #999;
  }

  .ideditor button.action.disabled:hover, .ideditor button.action[disabled]:hover
  {
    background: #333;
    color: #999;
  }

  .ideditor .tag-text.readonly, .ideditor .tag-row.readonly, .ideditor .tag-row.readonly input.key, .ideditor .tag-row.readonly input.value, .ideditor .tag-row.readonly button.remove
  {
    background-color: #222;
    color: #999;
  }

  .ideditor button.action
  {
    background: hsl(226,50%,50%);
  }

  .ideditor button.action:hover
  {
    background: hsl(226,50%,55%);
  }

  .ideditor.mode-save .changeset-list
  {
    background: #222;
    border-color: #444;
  }

  .ideditor.mode-save .changeset-list li:hover
  {
    background-color: #333;
  }

  .ideditor .save-communityLinks
  {
    border-top-color: #444;
  }

  .ideditor .community-event, .ideditor .community-more
  {
    background-color: #333;
  }

  /* Top Panel */
  .ideditor .joined>*
  {
    border-right-color: rgba(255,255,255,.1);
  }

  .ideditor button.disabled
  {
    background-color: rgba(0,0,0,.25);
    color: rgba(255,255,255,.4);
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 253);"]
  {
    background: #333332 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 250);"]
  {
    background: #333332 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 248);"]
  {
    background: #333331 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 245);"]
  {
    background: #333331 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 243);"]
  {
    background: #333330 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 241);"]
  {
    background: #333330 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 238);"]
  {
    background: #333329 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 236);"]
  {
    background: #333329 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 234);"]
  {
    background: #333328 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 231);"]
  {
    background: #333328 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 229);"]
  {
    background: #333327 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 226);"]
  {
    background: #333327 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 224);"]
  {
    background: #333326 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 222);"]
  {
    background: #333326 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 219);"]
  {
    background: #333325 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 217);"]
  {
    background: #333325 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 215);"]
  {
    background: #333324 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 212);"]
  {
    background: #333324 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 210);"]
  {
    background: #333323 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 207);"]
  {
    background: #333323 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 205);"]
  {
    background: #333322 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 203);"]
  {
    background: #333322 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 200);"]
  {
    background: #333321 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 198);"]
  {
    background: #333321 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 196);"]
  {
    background: #333320 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 193);"]
  {
    background: #333320 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 191);"]
  {
    background: #333319 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 188);"]
  {
    background: #333319 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 186);"]
  {
    background: #333318 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 184);"]
  {
    background: #333318 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 181);"]
  {
    background: #333317 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 179);"]
  {
    background: #333317 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 176);"]
  {
    background: #333316 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 174);"]
  {
    background: #333316 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 172);"]
  {
    background: #333315 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 169);"]
  {
    background: #333315 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 167);"]
  {
    background: #333314 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 165);"]
  {
    background: #333314 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 162);"]
  {
    background: #333313 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 160);"]
  {
    background: #333313 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 157);"]
  {
    background: #333312 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 155);"]
  {
    background: #333312 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 153);"]
  {
    background: #333311 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 150);"]
  {
    background: #333311 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 148);"]
  {
    background: #333310 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 146);"]
  {
    background: #333310 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 143);"]
  {
    background: #333309 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 141);"]
  {
    background: #333309 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 138);"]
  {
    background: #333308 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 255, 136);"]
  {
    background: #333308 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 253, 136);"]
  {
    background: #333307 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 250, 136);"]
  {
    background: #333307 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 248, 136);"]
  {
    background: #333306 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 245, 136);"]
  {
    background: #333306 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 243, 136);"]
  {
    background: #333305 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 241, 136);"]
  {
    background: #333305 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 238, 136);"]
  {
    background: #333304 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 236, 136);"]
  {
    background: #333304 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 234, 136);"]
  {
    background: #333303 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 231, 136);"]
  {
    background: #333303 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 229, 136);"]
  {
    background: #333302 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 226, 136);"]
  {
    background: #333302 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 224, 136);"]
  {
    background: #333301 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 222, 136);"]
  {
    background: #333301 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 219, 136);"]
  {
    background: #333300 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 217, 136);"]
  {
    background: #333300 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 215, 136);"]
  {
    background: #333200 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 212, 136);"]
  {
    background: #333200 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 210, 136);"]
  {
    background: #333100 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 207, 136);"]
  {
    background: #333100 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 205, 136);"]
  {
    background: #333000 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 203, 136);"]
  {
    background: #333000 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 200, 136);"]
  {
    background: #332900 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 198, 136);"]
  {
    background: #332900 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 196, 136);"]
  {
    background: #332800 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 193, 136);"]
  {
    background: #332800 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 191, 136);"]
  {
    background: #332700 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 188, 136);"]
  {
    background: #332700 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 186, 136);"]
  {
    background: #332600 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 184, 136);"]
  {
    background: #332600 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 181, 136);"]
  {
    background: #332500 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 179, 136);"]
  {
    background: #332500 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 176, 136);"]
  {
    background: #332400 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 174, 136);"]
  {
    background: #332400 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 172, 136);"]
  {
    background: #332300 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 169, 136);"]
  {
    background: #332300 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 167, 136);"]
  {
    background: #332200 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 165, 136);"]
  {
    background: #332200 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 162, 136);"]
  {
    background: #332100 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 160, 136);"]
  {
    background: #332100 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 157, 136);"]
  {
    background: #332000 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 155, 136);"]
  {
    background: #332000 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 153, 136);"]
  {
    background: #331900 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 150, 136);"]
  {
    background: #331900 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 148, 136);"]
  {
    background: #331800 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 146, 136);"]
  {
    background: #331800 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 143, 136);"]
  {
    background: #331700 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 141, 136);"]
  {
    background: #331700 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 138, 136);"]
  {
    background: #331600 !important;
  }

  .ideditor button.bar-button.save[style="background: rgb(255, 136, 136);"]
  {
    background: #331600 !important;
  }

  /* Map */
  .ideditor .main-map
  {
    color: #333;
  }

  .ideditor .tooltip
  {
    color: #eee;
  }

  .ideditor .popover.top .popover-arrow
  {
    border-top-color: #333;
  }

  .ideditor .popover.left .popover-arrow
  {
    border-left-color: #333;
  }

  .ideditor .popover.right .popover-arrow
  {
    border-right-color: #333;
  }

  .ideditor .popover.bottom .popover-arrow
  {
    border-bottom-color: #333;
  }

  .ideditor .tooltip .popover-inner
  {
    background-color: #333;
  }

  .ideditor .keyhint-wrap
  {
    background: #444;
  }

  .ideditor kbd
  {
    background-color: #666;
    border-color: #666;
    border-bottom-color: #666;
    box-shadow: 0 2px 0 #333;
  }

  .ideditor .edit-menu
  {
    background: #222;
  }

  .ideditor .edit-menu-item.disabled
  {
    background: none;
  }

  .ideditor button.disabled .icon.operation use, .ideditor .icon.operation.disabled use
  {
    fill: rgba(255,255,255,.2);
    color: rgba(255,255,255,.2);
  }

  .ideditor .tooltip-heading
  {
    background: #444;
  }

  .ideditor .modal .loader
  {
    filter: invert(1) contrast(73%);
  }

  /* Right Panel */
  .ideditor .map-control>button.active, .ideditor .map-control>button.active:active
  {
    background: hsl(226,50%,50%);
  }

  .ideditor .map-control>button.active:hover
  {
    background: hsl(226,50%,50%);
  }

  /* Background List */
  .ideditor .pane-heading
  {
    border-bottom-color: #444;
  }

  .ideditor .layer-list, .ideditor .controls-list
  {
    border-color: #444;
  }

  .ideditor .layer-list>li
  {
    background-color: #222;
    color: hsl(226,100%,80%);
  }

  .ideditor .layer-list li:not(:last-child)
  {
    border-bottom-color: #444;
  }

  .ideditor .layer-list li:hover
  {
    background-color: #333;
  }

  .ideditor .layer-list li.active button, .ideditor .layer-list li.switch button, .ideditor .layer-list li.active, .ideditor .layer-list li.switch
  {
    background: hsl(232,15%,25%);
  }

  .ideditor .tooltip.dark.top .popover-arrow, .ideditor .map-pane .tooltip.top .popover-arrow, .ideditor .sidebar .tooltip.top .popover-arrow
  {
    border-top-color: #444;
  }

  .ideditor .tooltip.dark.left .popover-arrow, .ideditor .map-pane .tooltip.left .popover-arrow, .ideditor .sidebar .tooltip.left .popover-arrow
  {
    border-left-color: #444;
  }

  .ideditor .tooltip.dark.bottom .popover-arrow, .ideditor .map-pane .tooltip.bottom .popover-arrow, .ideditor .sidebar .tooltip.bottom .popover-arrow
  {
    border-bottom-color: #444;
  }

  .ideditor .tooltip.dark .popover-inner, .ideditor .tooltip.dark .tooltip-heading, .ideditor .tooltip.dark .keyhint-wrap, .ideditor .map-pane .popover-inner, .ideditor .map-pane .tooltip-heading, .ideditor .map-pane .keyhint-wrap, .ideditor .sidebar .popover-inner, .ideditor .sidebar .tooltip-heading, .ideditor .sidebar .keyhint-wrap
  {
    background: #444;
  }

  .ideditor .tooltip.dark kbd, .ideditor .map-pane .tooltip kbd, .ideditor .sidebar .tooltip kbd
  {
    border-color: #666;
    border-bottom-color: #666;
    box-shadow: 0 2px 0 #333;
  }

  .ideditor .map-data-pane .layer-list button, .ideditor .background-pane .layer-list button
  {
    border-left-color: #444;
  }

  .ideditor code
  {
    background: rgba(255,255,255,.1);
    color: #eee;
  }

  /* Display Options */
  .ideditor .display-control button.disabled
  {
    background: none;
  }

  /* Imagery Offset */
  .ideditor .nudge-container button
  {
    background: none;
  }

  .ideditor .background-pane .nudge.top::after
  {
    border-bottom-color: #eee;
  }

  .ideditor .background-pane .nudge.left::after
  {
    border-right-color: #eee;
  }

  .ideditor .background-pane .nudge.right::after
  {
    border-left-color: #eee;
  }

  .ideditor .background-pane .nudge.bottom::after
  {
    border-top-color: #eee;
  }

  .ideditor .background-pane .nudge-container
  {
    border-color: #444;
  }

  .ideditor .nudge-container .nudge-outer-rect
  {
    background-color: #333;
    border-color: #444;
  }

  .ideditor .nudge-container .nudge-inner-rect
  {
    background-color: #222;
    border-color: #444;
  }

  .ideditor .nudge-container .nudge-inner-rect input
  {
    background: none;
    color: #eee;
  }

  /* Data Layers */
  #iD-icon-framed-dot
  {
    fill: #eee;
  }

  /* Issues */
  .ideditor .section-issues-status .box
  {
    background: hsl(124,30%,25%);
    border-color: hsl(124,30%,30%);
  }

  .ideditor .section-issues-status .icon
  {
    color: hsl(124,100%,70%);
  }

  /* Help */
  .ideditor .help-pane .toc li a, .ideditor .help-pane .nav a
  {
    border-color: #444;
  }

  .ideditor .help-pane .toc li a:hover, .ideditor .help-pane .nav a:hover
  {
    background: #333;
  }

  .ideditor .help-pane .toc li:nth-last-child(3) a
  {
    border-bottom-color: #444;
  }

  .ideditor .help-pane .toc li a.selected
  {
    background: hsl(232,15%,25%);
  }

  .ideditor .help-pane .toc li.shortcuts a, .ideditor .help-pane .toc li.walkthrough a
  {
    border-bottom-color: #444;
  }

  .ideditor .icon.operation use
  {
    fill: #eee;
  }

  .ideditor .help-pane svg.icon.pre-text.add-note, .ideditor button.add-note svg.icon
  {
    color: rgba(255,255,255,.25);
    stroke: #eee;
  }

  /* Hotkeys */
  .ideditor .modal-section
  {
    border-bottom-color: #444;
  }

  .ideditor .modal-shortcuts .tab
  {
    color: #aaa;
  }

  .ideditor .modal-shortcuts .tab:hover
  {
    background-color: #333;
    color: hsl(226,100%,85%);
  }

  .ideditor .modal-shortcuts .tab.active
  {
    color: hsl(226,100%,80%);
  }

  .ideditor table
  {
    background-color: #222;
  }

  .ideditor .modal-shortcuts .shortcut-keys kbd
  {
    color: #eee;
  }

  .ideditor .modal-shortcuts .shortcut-keys
  {
    color: #aaa;
  }

  .ideditor .modal-shortcuts .shortcut-keys .gesture
  {
    color: #eee;
  }
}
