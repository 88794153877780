[theme='dark'] {
  .Datepicker {
    .rdrCalendarWrapper {
      background-color: transparent;
    }

    .rdrDayNumber span {
      color: var(--text-main);
    }

    .rdrDayPassive {
      opacity: 0.5;
    }

    .rdrDateDisplayItem {
      background-color: transparent;

      input {
        color: var(--text-main);
      }
    }

    .rdrMonthAndYearPickers select {
      color: var(--text-main);
    }

    .rdrNextPrevButton {
      background: var(--theme-main);

      &.rdrNextButton {
        i {
          border-left-color: white;
        }
      }

      &.rdrPprevButton {
        i {
          border-right-color: white;
        }
      }
    }

    &-close,
    &-submit,
    &-cancel,
    &-today {
      color: var(--text-main);
    }
  }
}
