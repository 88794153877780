.CarControl {
  margin-top: 16px;

  &-buttonsContainer {
    width: 56px;
    align-self: stretch;

    button {
      & + button {
        margin-top: 16px;
      }
    }
  }
}

$trunk: #6AD27B;
$webasto: #6587FF;
$search: #FFB74A;
$block: #EB353D;

.CarControlButton {
  width: 56px;
  height: 56px;
  border: 1px solid;
  border-radius: 50%;

  .Svg {
    width: 20px;
    height: 20px;
  }

  &-trunk {
    border-color: $trunk;
  }

  &-aux,
  &-webasto {
    border-color: $webasto;

    path {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        stroke: $webasto;
      }

      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        fill: $webasto;
      }
    }
  }

  &-aux {
    .Svg {
      width: 38px;
      height: 10px;
    }
  }

  &-dashcam {
    border-color: $webasto;

    path,
    rect {
      fill: $webasto;
    }
  }

  &-search {
    border-color: $search;
  }

  &-block,
  &-blockActive {
    border-color: $block;
  }

  &-active {
    path {
      fill: #FFFFFF;
    }

    &.CarControlButton {
      &-trunk {
        background-color: $trunk;
      }

      &-webasto {
        background-color: $webasto;

        path {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3) {
            stroke: white;
            fill: none;
          }

          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            fill: white;
            stroke: none;
          }
        }

        circle {
          fill: $webasto;
        }
      }

      &-aux {
        background-color: $webasto;

        path {
          fill: white;
          stroke: white;
        }
      }

      &-dashcam {
        background-color: $webasto;

        path,
        rect {
          fill: white;
        }
      }

      &-search {
        background-color: $search;
      }

      &-block,
      &-blockActive {
        background-color: $block;
      }
    }
  }

  &.CarControlButton-large {
    width: 132px;
    height: 132px;

    .Svg {
      width: 40px;
      height: 40px;
    }
  }
}

[theme='dark'] {
  .CarControlButton {
    border-color: white;
    background-color: transparent;

    path {
      fill: white;
    }

    &-webasto {
      path {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          stroke: white;
        }

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          fill: white;
        }
      }
    }

    &-active {
      border-color: var(--theme-main);
      background-color: var(--theme-main);

      path {
        fill: black;
      }

      &.CarControlButton {
        &-webasto {
          path {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              stroke: black;
              fill: none;
            }

            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
              fill: black;
              stroke: none;
            }
          }
        }
      }
    }
  }
}
