@import '../../scss/variables';

.ReferencePage {
  border-top: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;

  .SettingsLink-label {
    margin-left: 0;
  }
}
