.Grid {
  &-container {
    box-sizing: border-box;
    display: flex;
    width: 100%;
  }

  &-item {
    box-sizing: border-box;
    margin: 0;
  }

  &-direction {
    &-column {
      flex-direction: column;
    }
  }

  &-justify {
    &-center {
      justify-content: center;
    }

    &-space-between {
      justify-content: space-between;
    }

    &-space-around {
      justify-content: space-around;
    }
  }

  &-align {
    &-center {
      align-items: center;
    }

    &-flex-end {
      align-items: flex-end;
    }
  }
}
