@import '../../scss/variables';

.DevicesPage {
  height: calc(100% - #{$settingsBackButtonHeight});

  &-divider {
    width: 100%;
    height: 1px;
    margin-top: 16px;
    background-color: $borderColor;
  }

  .DevicesList {
    height: 100%;
    padding: 16px 16px;
    overflow-y: auto;
  }
}
