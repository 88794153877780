@import '../../scss/variables';

.SettingsLink {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 16px;
  text-decoration: none;
  cursor: pointer;

  .Svg + &-labelContainer {
    margin-left: 12px;
  }

  &-labelContainer {
    flex: 1;
    display: flex;
    flex-direction: column;

    .Typography:last-child {
      margin-top: 2px;
    }
  }

  &-action {
    margin-left: auto;
  }

  .Svg,
  .Svg div,
  .Svg svg {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
  }

  .Svg:not(.SettingsLink-action) {
    path,
    rect,
    circle {
      fill: var(--text-second);
    }
  }
  
  &-disabled {
    pointer-events: none;
    background-color: $borderColor;
  }
}

[theme='dark'] {
  .SettingsLink {
    .Svg:not(.SettingsLink-action) {
      path,
      rect,
      circle {
        fill: var(--text-main);
      }
    }

    .SettingsLink-action {
      path {
        stroke: var(--text-main);
      }
    }
  }
}
