@import '../../scss/variables';

.NavBar {
  position: relative;
  border-top: 1px solid var(--border-color);

  .NavBar-status {
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: var(--theme-main);
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    will-change: left, width;
  }
}
