.DeviceInfo {
  margin-top: 20px;
  padding-bottom: 20px;
}

.DeviceInfoItem {
  &-icon {
    width: 16px;
    height: 16px;

    path {
      fill: var(--text-second);
    }
  }

  &-alertIcon {
    width: 3px;
    height: 16px;
    margin-left: 6px;
  }

  & + .DeviceInfoItem {
    margin-left: 20px;
  }

  &-value {
    margin-top: 10px;
    white-space: nowrap;
  }
}
