.EventAlert {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-flex;
  max-width: 280px;
  text-align: center;
  border-radius: 20px;
  opacity: 0;
  background-color: rgba(black, 0.4);
  color: white;
  padding: 6px 16px;
  z-index: 1000;

  &-visible {
    opacity: 1;
  }
}
