@import "./variables";
@import '~normalize.css';
@import "./datepicker";
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');

:root {
  --app-background: #ffffff;
  --background-color: #{$backgroundColor};
  --text-main: #{$textMain};
  --text-second: #{$textSecond};
  --text-third: #{$textThird};
  --text-third-darken: #{$textThirdDarken};
  --border-color: #{$borderColor};
  --theme-main: #{$themeMain};
  --theme-main-darken: #{$themeMainDarken};
  --theme-main-lighten: #{$themeMainLighten};
}

:root[theme='dark'] {
  --app-background: #{$darkThemeBackground};
  --background-color: #{$darkThemeBackground};
  --text-main: #{$darkThemeText};
  --text-second: #{$darkThemeText};
  --text-third: #{$darkThemeText};
  --text-third-darken: #{$darkThemeText};
  --border-color: #{$darkThemeBorder};
  --theme-main: #{$darkThemeMain};
  --theme-main-darken: #{$darkThemeMain};
  --theme-main-lighten: #{$darkThemeMain};
}

html {
  font-family: 'Roboto', sans-serif;
}

body {
  background-color: var(--app-background);
}


