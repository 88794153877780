@import '../../scss/variables';

.ClickDelayLoader {
  position: absolute;
  left: 0;
  top: 0;
  height: 3px;
  width: 100%;

  &-wrapper {
    position: relative;
    height: 100%;
  }

  &-progress {
    height: 3px;
    width: 0;
    background-color: var(--theme-main);

  }

  &-visible {
    .ClickDelayLoader-progress {
      animation: 1s linear 0s delay;
    }
  }
}

@keyframes delay {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}
